<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex align-self-center>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">My Salary advance applications</span>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="green"
            @click="$router.push('/MyLoanApplications')"
            >Loan Applications</v-btn
          >
          <v-flex xs12 align-self-center py-2>
            <v-layout wrap justify-end>
              <v-flex align-self-center xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-select
                  v-model="status"
                  :items="statusList"
                  outlined
                  dense
                  hide-details
                  class="rounded-xl"
                  label="Status"
                  clearable
                >
                </v-select>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-center v-if="loanList.length > 0">
          <v-flex xs12 align-self-center>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Date</th>
                    <th class="text-left">Amount</th>
                    <th class="text-left">Type</th>
                    <th>numberOfInstallments</th>
                    <th class="text-left">Advance required by</th>
                    <th class="text-left">Status</th>
                    <th class="text-left">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="text-left align-center"
                    v-for="(item, i) in loanList"
                    :key="i"
                  >
                    <td>
                      <span
                        v-if="item.create_date"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.create_date.slice(0, 10) }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.amount"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.amount }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.type"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.type }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.numberOfInstallments"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.numberOfInstallments }}</span
                      >
                    </td>
                    <td v-if="item.advanceDate">
                      <span>{{ formatDate(item.advanceDate) }}</span>
                    </td>
                    <td>
                      <span
                        v-if="item.status"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.status }}</span
                      >
                    </td>
                    <td>
                      <span v-if="item.status === 'Pending'">
                        <v-icon
                          small
                          color="primary"
                          @click="handleEdit(item)"
                          style="cursor: pointer"
                        >
                          mdi-pencil </v-icon
                        >&nbsp;<v-icon
                          small
                          color="red"
                          @click="handleDelete(item)"
                          style="cursor: pointer"
                          >mdi-delete</v-icon
                        >
                      </span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>
        <v-layout wrap v-else>
          <v-flex xs12 align-self-center pa-16>
            <span class="kumbhRegular">No Items found!</span>
          </v-flex>
        </v-layout>
        <v-dialog persistent v-model="dialog2" width="70%">
          <v-card>
            <v-toolbar dense dark>
              <v-spacer></v-spacer>
              <v-btn right icon dark @click="(doc = null), (dialog2 = false)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-title class="text-h5 grey lighten-2">
              Document
            </v-card-title>
            <v-card-text v-if="doc">
              <v-img :src="mediaURL + doc"></v-img>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                small
                color="red"
                text
                @click="(dialog2 = false), (doc = null)"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="docdialog" width="70%">
          <v-card>
            <v-layout wrap>
              <v-flex xs12 pa-2 text-left> Supporting documents </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-layout wrap>
              <v-flex xs12 pa-4>
                <v-chip-group v-if="curitem.loanDocument">
                  <v-chip
                    v-for="(item2, i) in curitem.loanDocument"
                    :key="i"
                    class=""
                    style="font-family: kumbhMedium"
                    ><span v-if="item2">
                      {{ item2 }}
                      &nbsp;
                      <span
                        v-if="
                          item2.split('.').pop() == 'pdf' ||
                          item2.split('.').pop() == 'docx'
                        "
                      >
                        <a target="_blank" :href="mediaURL + item2">
                          <v-btn color="green" rounded outlined x-small>
                            View
                          </v-btn></a
                        ></span
                      >
                      <span v-else>
                        <v-btn
                          @click="(doc = item2), (dialog2 = true)"
                          color="green"
                          rounded
                          outlined
                          x-small
                        >
                          View
                        </v-btn>
                      </span>
                    </span></v-chip
                  >
                </v-chip-group>
              </v-flex>
            </v-layout>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined small color="red" text @click="docdialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#005f32"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
    <v-dialog persistent v-model="deleteDialog" max-width="600px">
      <v-card>
        <v-layout wrap>
          <v-flex
            xs12
            align-self-center
            pa-4
            style="background: #005f32"
            text-left
          >
            <span class="kumbhBold" style="color: #ffffff">Confirmation</span>
          </v-flex>
        </v-layout>
        <v-layout wrap pa-6>
          <v-flex xs12 align-self-end pa-3>
            <span class="kumbhMedium"
              >Are you sure you want to delete this salary advance
              application?</span
            >
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" tile dark @click="deleteDialog = false"
            >Cancel</v-btn
          >
          &nbsp;
          <v-btn color="#005f32" outlined tile @click="confirmDelete"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
import axios from "axios";
export default {
  data() {
    return {
      show: false,
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      totalData: 0,
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      idd: null,
      loanList: [],
      data: [],
      //view doc
      curitem: [],
      docdialog: false,
      dialog2: false,
      doc: null,

      //
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      DataCover: new FormData(),
      name: null,
      assetId: null,
      assetList: [],
      date: new Date(),
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
      status: null,

      statusList: ["Pending", "Approved", "Rejected"],
      employeeId: null,
      departmentId: null,
      search1: null,

      empList: [],
      deptList: [],
      quantity: null,
      deleteDialog: false,
      itemToDelete: null,
    };
  },
  components: {
    // ImageComp,
  },
  computed: {
    userId() {
      return this.$store.state.userData._id;
    },
  },
  watch: {
    // fromDate() {
    //   if (this.currentPage > 1) this.currentPage = 1;
    //   this.getData();
    // },
    // toDate() {
    //   if (this.currentPage > 1) this.currentPage = 1;
    //   this.getData();
    // },
    status() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },
  },
  mounted() {
    this.getData();
    // if (this.fromDate) {
    //   this.fromDate = null;
    // }
    // if (this.toDate) {
    //   this.toDate = null;
    // }
    // if(this.userId){
    // this.getData();
    // }
  },

  methods: {
    handleDelete(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },
    confirmDelete() {
      this.appLoading = true;
      axios({
        url: "/salaryadvance/application/cancel",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.itemToDelete._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.deleteDialog = false;
          if (response.data.status) {
            this.msg = "Application deleted successfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg || "Failed to delete application";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.deleteDialog = false;
          this.msg = "Error deleting application";
          this.showsnackbar = true;
          console.log(err);
        })
        .finally(() => {
          this.itemToDelete = null;
        });
    },
    //  userdata() {
    //   this.appLoading = true;
    //   axios({
    //     method: "get",
    //     url: "/employee/profile/me",
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       if (response.data.status==true) {
    //         this.idd=response.data.data._id;
    //         console.log("idd=",this.idd)
    //         this.getData();
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       console.log(err);
    //     });
    // },
    handleEdit(item) {
      this.$router.push({
        path: "/EditAdvance",
        query: { id: item._id },
      });
    },
    getData() {
      this.appLoading = true;
      axios({
        // url: "/salaryAdvance_Loans/own/list",
        url: "/salaryadvance/application/getlist",
        method: "get",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          status: this.status,
          employeeId: this.userId,
          page: this.currentPage,
          limit: 20,
          // from: this.fromDate,
          // to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.loanList = response.data.data;
          this.totalData = response.data.totalLength;
          console.log("userData", this.userId);
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/assetCategory/remove",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            console.log("5");
            this.getData();
          } else {
            console.log("6");
            this.getData();
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    editcat(item) {
      this.editingitem = item;

      this.getEmp();
      // if (item.departmentId) this.departmentId = item.departmentId._id;
      // if (item.employeeId) this.employeeId = item.employeeId._id;
      this.editdialog = true;
    },
    validateEdit() {
      if (this.quantity > this.editingitem.quantity) {
        this.msg =
          "Quantity should be less than or same as already assigned quantity!";
        this.showsnackbar = true;
        return;
      } else if (!this.assignType) {
        this.msg = "Please provide assign type";
        this.showsnackbar = true;
        return;
      } else if (!this.departmentId) {
        this.msg = "Please choose a department";
        this.showsnackbar = true;
        return;
      } else if (this.assignType == "Employee" && !this.employeeId) {
        this.msg = "Please choose an employee";
        this.showsnackbar = true;
        return;
      } else {
        this.edit();
      }
    },
    edit() {
      var data = {};
      data["inventoryStockId"] = this.editingitem._id;
      data["assignType"] = this.assignType;
      data["departmentId"] = this.departmentId;
      data["employeeId"] = this.employeeId;
      data["quantity"] = this.quantity;
      axios({
        url: "/asset/reassign/transfer",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            console.log("4");
            this.getData();
            this.clearOff();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    // formatDate(item) {
    //   var dt = new Date(item);
    //   var day = dt.getDate();
    //   var year = dt.getFullYear();
    //   var hours = dt.getHours();
    //   var minutes = dt.getMinutes();
    //   dt = dt.toString();
    //   var ampm = hours >= 12 ? "pm" : "am";
    //   hours = hours % 12;
    //   hours = hours ? hours : 12;
    //   minutes = minutes < 10 ? "0" + minutes : minutes;
    //   day = day < 10 ? "0" + day : day;
    //   var strTime =
    //     day +
    //     " " +
    //     dt.slice(4, 7) +
    //     " " +
    //     year +
    //     " " +
    //     hours +
    //     ":" +
    //     minutes +
    //     " " +
    //     ampm;
    //   return strTime;
    // },

    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      return strTime;
    },

    clearOff() {
      this.employeeId = null;
      this.assignType = null;
      this.quantity = null;
      this.departmentId = null;
    },
  },
};
</script>
